import React from "react";
import { graphql } from "gatsby";
import Sports from "../../components/Sports";

export default (props) => <Sports {...props} />;

export const pageQuery = graphql`
  query SportsENQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "sport-page-en" }
          nopage: { ne: "true" }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            sport_image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
